<template>
  <div justify="end">
    <v-dialog
      v-model="isVisibleShow"
      max-width="1200px"
    >
      <v-card elevation="0">
        <v-card-title class="pb-4">
          <span class="text-h3">{{ $t("product") }}</span>
        </v-card-title>
        <v-card-text class="pa-0 grey lighten-4">
          <v-container>
            <v-row>
              <v-col
                cols="12"
                sm="4"
                md="4"
                class="px-10"
              >
                <v-img
                  v-if="product.image"
                  :src="product.image.url"
                />
                <v-img
                  v-else
                  src="@/assets/product.png"
                />
              </v-col>
              <v-col
                cols="12"
                sm="8"
                md="8"
              >
                <v-card elevation="0">
                  <v-card-text>
                    <v-simple-table>
                      <template v-slot:default>
                        <tbody>
                          <tr>
                            <td>{{ $t("name") }}</td>
                            <td>{{ product.name }}</td>
                          </tr>
                          <tr>
                            <td>{{ $t("products.marketing_designation") }}</td>
                            <td>{{ product.name_marketing }}</td>
                          </tr>
                          <tr>
                            <td>{{ $t("sku") }}</td>
                            <td>{{ product.sku }}</td>
                          </tr>
                          <tr>
                            <td>{{ $t("provider") }}</td>
                            <td>
                              {{
                                product.provider
                                  ? product.provider.company_name
                                  : "_"
                              }}
                            </td>
                          </tr>
                          <tr>
                            <td>{{ $t("description") }}</td>
                            <td>{{ product.description }}</td>
                          </tr>
                          <tr>
                            <td>{{ $t("purchase_price") }}</td>
                            <td>{{ product.purchase_price }} {{ user.currency }} </td>
                          </tr>
                          <tr>
                            <td>{{ $t("price") }}</td>
                            <td>{{ product.price }} {{ user.currency }}</td>
                          </tr>
                          <tr>
                            <td>{{ $t("category") }}</td>
                            <td>
                              {{
                                product.category ? product.category.name : "_"
                              }}
                            </td>
                          </tr>
                          <tr>
                            <td>{{ $t("brand") }}</td>
                            <td>
                              {{ product.brand ? product.brand.name : "_" }}
                            </td>
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
            <v-row v-if="product.hubs && product.hubs.length > 0">
              <v-col
                cols="12"
                sm="12"
                md="12"
              >
                <v-card elevation="0">
                  <v-card-title class="text-left">
                    <span>{{ $t("hubs") }}</span>
                  </v-card-title>
                  <v-card-text>
                    <v-simple-table>
                      <template v-slot:default>
                        <thead>
                          <tr>
                            <th class="text-left">
                              {{ $t("name") }}
                            </th>
                            <th class="text-left">
                              {{ $t("quantities") }}
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            v-for="item in product.hubs"
                            :key="item.id"
                          >
                            <td>{{ item.name }}</td>
                            <td>{{ item.qte_in_hub }}</td>
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
            <v-row v-if="product.variants && product.variants.length > 0">
              <v-col
                cols="12"
                sm="12"
                md="12"
              >
                <v-card elevation="0">
                  <v-card-title class="text-left">
                    <span>{{ $t("variants") }}</span>
                  </v-card-title>
                  <v-card-text>
                    <v-simple-table>
                      <template v-slot:default>
                        <thead>
                          <tr>
                            <th class="text-left">
                              {{ $t("name") }}
                            </th>
                            <th class="text-left">
                              {{ $t("sku") }}
                            </th>
                            <th class="text-left">
                              {{ $t("quantities") }}
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            v-for="item in product.variants"
                            :key="item.id"
                          >
                            <td>{{ item.name }}</td>
                            <td>{{ item.sku }}</td>
                            <td>{{ item.qte }}</td>
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
            <v-row v-if="product.bundleItems && product.bundleItems.length > 0">
              <v-col
                cols="12"
                sm="12"
                md="12"
              >
                <v-card elevation="0">
                  <v-card-title class="text-left">
                    <span>{{ $t("items") }}</span>
                  </v-card-title>
                  <v-card-text>
                    <v-simple-table>
                      <template v-slot:default>
                        <thead>
                          <tr>
                            <th class="text-left">
                              {{ $t("name") }}
                            </th>
                            <th class="text-left">
                              {{ $t("sku") }}
                            </th>
                            <th class="text-left">
                              {{ $t("code") }}
                            </th>
                            <th class="text-left">
                              {{ $t("quantities") }}
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            v-for="item in product.bundleItems"
                            :key="item.id"
                          >
                            <td>{{ item.name }}</td>
                            <td>{{ item.sku }}</td>
                            <td>{{ item.code }}</td>
                            <td>{{ item.qte_in_bundle }}</td>
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
            <v-row v-if="product.lots && product.lots.length > 0">
              <v-col
                cols="12"
                sm="12"
                md="12"
              >
                <v-card elevation="0">
                  <v-card-title class="text-left">
                    <span>{{ $t("lots") }}</span>
                  </v-card-title>
                  <v-card-text>
                    <v-simple-table>
                      <template v-slot:default>
                        <thead>
                          <tr>
                            <th class="text-left">
                              {{ $t("lot_number") }}
                            </th>
                            <th class="text-left">
                              {{ $t("expiration_date") }}
                            </th>
                            <th class="text-left">
                              {{ $t("hub_name") }}
                            </th>
                            <th class="text-left">
                              {{ $t("quantities") }}
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            v-for="item in product.lots"
                            :key="item.id"
                          >
                            <td>{{ item.lot_number }}</td>
                            <td>{{ item.expiration_date }}</td>
                            <td>{{ item.hub.name }}</td>
                            <td>{{ item.qte_in_lot }}</td>
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="red darken-1"
            text
            @click="closeForm()"
          >
            {{ $t("close") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters({
      isLoadingProduct: "products/isLoadingProduct",
      product: "products/product",
      user: "users/editedUser",

    }),

    isVisibleShow: {
      get() {
        return this.$store.state.products.isVisibleShow;
      },
      set(value) {
        this.$store.commit("products/IS_VISIBLE_SHOW", value);
      },
    },
  },
    mounted() {
    const editedUserJSON = localStorage.getItem("user");
    if (editedUserJSON) {
      const editedUser = JSON.parse(editedUserJSON);
      this.$store.commit("users/EDITED_USER", editedUser);
    }
  },

  data() {
    return {};
  },
  methods: {
    closeForm() {
      this.$store.dispatch("products/closeForm");
    },
  },
};
</script>
